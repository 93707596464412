.TradeCommentFeed .comment-feed-container {
    padding-bottom: 192px;
}

.TradeCommentFeed .comment-feed-container .new-comment-container {
    position: fixed;
    bottom: 0;
    display: flex;
    flex-direction: row;
}
.TradeCommentFeed .comment-feed-container .new-comment {
    background-color: white;
    padding: 8px;
    flex-basis: 100%;
    border: 1px solid rgba(0,0,0,.125);
    box-shadow: 0 0 10px rgba(0,0,0,.3);
}
.TradeCommentFeed .comment-feed-container textarea {
    width: 100%;
}
.TradeCommentFeed .comment-feed-container .btn-primary {
    width: 100%;
}

@media only screen and (max-width: 1077px) {
    .TradeCommentFeed .comment-feed-container .new-comment-container {
        width: 696px;
        position: fixed;
        bottom: 0;
        display: flex;
        flex-direction: row;
    }
}

@media only screen and (max-width: 696px) {
    .TradeCommentFeed .comment-feed-container .new-comment-container {
        width: 100%;
        padding: 0;
        left: 0px;
    }
}
