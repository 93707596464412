.WineDetail {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    padding: 8px 16px 16px;
}
.WineDetail h1 {
    font-size: 2rem;
    text-align: center;
    margin: 16px;
}

.WineDetail a {
    color: rgba(17,172,192);
}

.WineDetail a:hover {
    color: white;
}

.WineDetail .content-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
}
.WineDetail .detail {
    /*width: 696px;*/
    width: 58%;
    /*flex-wrap: wrap;*/
    flex-wrap: nowrap;
    text-align: left;
    margin: 0 24px 0 0;
}

.WineDetail .detail .colPair {
     display: flex;
     flex-wrap: wrap;
     flex-direction: row;
     justify-content: space-between;
 }

.WineDetail .detail .colPair .rightColumn{
    justify-content: right;
    text-align: right;
}

.WineDetail .error {
    background-color: #FFCDD2;
    border-radius: 6px;
    padding: 8px;
    max-width: 540px;
    font-size: 1.25rem;
    box-shadow: 0 0 5px #cccccc;
}

.WineDetail .comments {
    /*width: 400px;*/
    width: 38%;
    /*flex-wrap: wrap;*/
    flex-wrap: nowrap;
    justify-content: center;
}

.WineDetail .topNavigateCard {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.WineDetail .error .topNavigateCard {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.WineDetail .topNavigateCard button {
    width: fit-content;
    height: fit-content;
    margin-left: 1em;
    margin-bottom: .5em;
}

.WineDetail .topNavigateCard label {
    font-size:1.2em;
}

.WineDetail .topNavigateCard input {
    width: fit-content;
}
.WineDetail .WineCommentFeed .comment-feed-container .new-comment-container {
    /*width: 100%;*/
}
.WineDetail .WineCommentFeed .comment-feed-container .new-comment-container .primary-button {
    width: 100%;
}

/*@media only screen and (max-width: 1152px) {*/
/*    .WineDetail .detail {*/
/*        width: 696px;*/
/*        flex-wrap: wrap;*/
/*        text-align: left;*/
/*        margin: 0;*/
/*    }*/
/*    .WineDetail .comments {*/
/*        width: 696px;*/
/*        flex-wrap: wrap;*/
/*        justify-content: center;*/
/*        margin: 16px 0 0 0 ;*/
/*    }*/
/*    .WineDetail .WineCommentFeed .comment-feed-container .new-comment-container {*/
/*        width: 696px;*/
/*    }*/
/*    .WineDetail .WineCommentFeed .comment-feed-container .new-comment-container .primary-button {*/
/*        width: 100%;*/
/*    }*/
/*}*/

@media only screen and (max-width: 850px) {
    .WineDetail .detail {
        width: 100%;
        flex-wrap: wrap;
        text-align: left;
        margin: 0;
    }
    .WineDetail .comments {
        width: 100%;
        flex-wrap: wrap;
        justify-content: center;
        margin: 16px 0 0 0 ;
    }
    .WineDetail .WineCommentFeed .comment-feed-container .new-comment-container {
        width: 100%;
    }
    .WineDetail .WineCommentFeed .comment-feed-container .new-comment-container .primary-button {
        width: 100%;
    }
}
