.ModalDialog {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
}

.ModalDialog .card {
    width: 480px;
    box-shadow: 0 0 5px #666666;
}

.ModalDialog .content-container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.ModalDialog .content-container .body {
    margin-bottom: 16px;
    width: 100%;
}
.ModalDialog .content-container .primary-button {
    width: 100%;
}

@media only screen and (max-width: 480px) {
    .ModalDialog .card {
        width: 100%;
        box-shadow: 0 0 5px #666666;
    }
}
