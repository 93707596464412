.AddField {
    display: flex;
    margin: 48px 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.AddField .card {
    max-width: 520px;
}

.AddField .primary-button {
    margin: 8px 0 0 0;
}

@media only screen and (max-width: 520px) {
    .AddField .card {
        max-width: 100%;
    }
}
