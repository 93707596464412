.AddRating {
    display: flex;
    flex-direction: column;
    padding: 16px;
    align-items: center;
}

.AddRating .card {
    margin: 16px 0;
    width: 664px;
}

.AddRating .primary-button {
    margin: 16px 0 0 0;
}

@media only screen and (max-width: 696px) {
    .AddRating {
        display: flex;
        flex-direction: column;
        padding: 16px 0;
        align-items: center;
    }
    .AddRating .card {
        margin: 16px 0;
        width: 98%;
    }
}
