.primary-button {
    color: #000000;
    background-color: #8fcfd1;
    border: 1px solid #8fcfd1;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    user-select: none;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    padding: 0.375rem 0.75rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.primary-button:hover {
    background-color: #2f6f72;
    color: #ffffff;
    text-decoration: none;
}

.negative-button {
    color: #ffffff;
    background-color: #dc3545;
    border: 1px solid #dc3545;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    user-select: none;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    padding: 0.375rem 0.75rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.negative-button:hover {
    background-color: #a3001e;
    text-decoration: none;
}

.list-container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 696px;
    padding: 0 8px;
}
@media only screen and (max-width: 696px) {
    .list-container {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        width: 100%;
    }
}

.card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 6px;
    box-shadow: 0 0 5px #cccccc;
    margin: 8px;
    text-align: center;
    padding: 8px 8px 12px;
}

.toggle-buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}
.toggle-buttons .toggle-button:last-child {
    margin: 0;
}
.toggle-buttons .toggle-button {
    flex-grow: 1;
    margin: 0 16px 0 0;
}

.card-item-title-link {
    color: rgba(55,110,110,1);
    display: block;
    margin-bottom: 8px;
    font-size: 1.25rem;
}

.comment-feed-container {
    padding-bottom: 192px;
}
.comment-feed-container .new-comment-container {
    position: fixed;
    bottom: 0;
    display: flex;
    flex-direction: row;
}
.comment-feed-container .new-comment {
    background-color: white;
    padding: 8px;
    flex-basis: 100%;
    border: 1px solid rgba(0,0,0,.125);
    box-shadow: 0 0 10px rgba(0,0,0,.3);
}
.comment-feed-container textarea {
    width: 100%;
}
.comment-feed-container .btn-primary {
    width: 100%;
}