
.AddWine {
    display: flex;
    padding: 16px 48px;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.AddWine .form {
    max-width: 600px;
    padding: 16px;
    border: solid 1px #000000;
    border-radius: 6px;
}

.AddWine .row {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 0 8px 0;
}

.AddWine .row .label {
    flex-grow: 1;
    font-size: 1.25em;
}

.AddWine .row input {
    flex-grow: 4;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    padding: 4px;
}

.AddWine .multi-select-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 0 0 8px 0;
}

.AddWine .multi-select-row button {
    margin: 4px;
}

.AddWine .submit {
    width: 568px;
    margin: 8px 0 0 0;
}

@media only screen and (max-width: 696px){
    .AddWine {
        display: flex;
        padding: 8px;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }
    .AddWine .form {
        padding: 16px;
        border: solid 1px #000000;
        border-radius: 6px;
    }
    .AddWine .submit {
        margin: 8px 0 0 0;
        width: 100%;
    }
}
