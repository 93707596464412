.OgExchangeList {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    padding: 16px;
}

.OgExchangeList .primary-button {
    margin: 0;
    width: 100%;
    max-width: 696px;
}

.OgExchangeList .content-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin: 16px 0 0 0 ;
}

.OgExchangeList .content-container .list-container {
    max-width: 400px;
}

.OgExchangeList .comments {
    padding: 0;
    width: 400px;
}

.OgExchangeList .content-container .comments .TradeCommentFeed {
    margin: 16px 0 0 0 ;
}

.OgExchangeList .OgExchangeItem {
    width: 100%;
    margin: 8px 0;
}

.OgExchangeList .TradeCommentFeed .new-comment-container {
    width: 400px;
}

.OgExchangeList .TradeCommentFeed .new-comment-container .primary-button {
    width: 100%;
}

@media only screen and (max-width: 1235px) {
    .OgExchangeList .detail {
        width: 696px;
        flex-wrap: wrap;
        text-align: left;
        margin: 0;
    }
    .OgExchangeList .comments {
        width: 696px;
        flex-wrap: wrap;
        justify-content: center;
        margin: 16px 0 0 0 ;
    }
    .OgExchangeList .TradeCommentFeed .new-comment-container {
        width: 696px;
    }
    .OgExchangeList .TradeCommentFeed .new-comment-container .primary-button {
        width: 100%;
    }
}

@media only screen and (max-width: 832px) {
    .OgExchangeList .content-container .list-container {
        max-width: 100%;
    }
}

@media only screen and (max-width: 696px) {
    .OgExchangeList .detail {
        width: 100%;
        flex-wrap: wrap;
        text-align: left;
        margin: 0;
    }
    .OgExchangeList .comments {
        width: 100%;
        flex-wrap: wrap;
        justify-content: center;
        margin: 16px 0 0 0 ;
    }
    .OgExchangeList .TradeCommentFeed .new-comment-container {
        width: 100%;
    }
    .OgExchangeList .TradeCommentFeed .new-comment-container .primary-button {
        width: 100%;
    }
}
