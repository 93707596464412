.LeaderBoard {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
}
.LeaderBoard h1 {
    font-size: 2.25rem;
    line-height: 3rem;
    text-align: center;
    margin: 0 0 16px;
}
.LeaderBoard .primary-button {
    width: 680px;
    margin: 8px;
}
.LeaderBoard .content-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row;
}
.LeaderBoard .content-container:last-child {
    margin-right: 0;
}
.LeaderBoard .solo {
    flex: 1;
    flex-wrap: wrap;
    justify-content: center;
    margin: 16px 8px 16px 0;
}

.LeaderBoard .group {
    flex: 1;
    flex-wrap: wrap;
    justify-content: center;
    margin: 16px 8px 16px 0;
}

.LeaderBoard .comments {
    width: 400px;
    flex-wrap: wrap;
    justify-content: center;
    margin: 16px 8px 16px 0;
}

.LeaderBoard .comments .new-comment-container {
    width: 400px;
}

.LeaderBoard .comments .new-comment-container .primary-button {
    width: 100%;
    margin: 0;
}

@media only screen and (max-width: 696px) {
    .LeaderBoard .primary-button {
        width: 98%;
        margin: 8px;
    }
    .LeaderBoard .comments {
        width: 100%;
        flex-wrap: wrap;
        justify-content: center;
        margin: 16px 0;
    }
}
