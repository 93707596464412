.WineList {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
}

.WineList .list-container{
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
}

.WineList .list-container .list-of-wines{
    display: flex;
    flex-direction: column;
    width: 58%;
    flex-wrap: nowrap;
    align-items: stretch;
    justify-content: center;
}

.WineList .list-container .list-of-comments{
    display: flex;
    flex-direction: column;
    width: 38%;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
}

.WineList .list-container .list-of-comments .CommentList {
    width: 100%;
}

.WineList .list-container h4 {
    border-bottom: thick double #2f6f72;
    border-top: thick double #2f6f72;
    width: 100%;
    margin-top: .5em;
}

.heading {
    /*border-radius: 6px;*/
    /*box-shadow: 0 0 5px #cccccc;*/
    /*border: thick double #32a1ce;*/
    /*padding-left: 10em;*/
    /*padding-right: 10em;*/
    box-shadow: 0 0 5px #2f6f72;

}

.WineList .primary-button {
    margin: 8px;
    width: 680px;
}

.WineList .WineFeed .primary-button {
    margin: 8px;
    width: 60%;
}

.WineList .card  .primary-button {
    margin: 8px;
    width: 300px;
}

.WineList .secondary-button {
    margin: 8px;
    width: 300px;
}

.WineList p {
    margin: 8px;
    width: 680px;
}

.WineList .WineItem {
    width: 100%;
    margin: 8px;
}

.AddReviewModalDialog {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    flex-direction: column;
    position: fixed;
    padding-left: 30px;
    padding-right: 30px;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
}

.AddReviewModalDialog .row {
    align-items: center;
    justify-content: center;
}

.btn-info, .btn-outline-info:hover{
    background-color:  #2f6f72 !important;
    border-color: #2f6f72 !important;
}

.btn-outline-info {
    color: #2f6f72 !important;
    border-color: #2f6f72 !important;
}

.btn-outline-info:focus, .btn-info:focus {
    box-shadow: 0 0 0 0;
}

@media only screen and (max-width: 696px) {
    .WineList .primary-button {
        margin: 8px;
        width: 98%;
    }

    .WineList .secondary-button {
        margin: 8px;
        width: 85%;
    }

    .WineList p {
        margin: 8px;
        width: 98%;
    }

}

