.EventList {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
}

.EventList .primary-button {
    margin: 8px;
    width: 680px;
}

.EventList .EventItem {
    width: 100%;
    margin: 8px 0;
}

@media only screen and (max-width: 696px) {
    .EventList .primary-button {
        margin: 8px;
        width: 98%;
    }
}