.LeaderCommentFeed .comment-feed-container {
    padding-bottom: 192px;
}

.LeaderCommentFeed .comment-feed-container .new-comment-container {
    display: flex;
    flex-direction: row;
    position: fixed;
    bottom: 0;
}
.LeaderCommentFeed .comment-feed-container .new-comment {
    background-color: white;
    padding: 8px;
    flex-basis: 100%;
    border: 1px solid rgba(0,0,0,.125);
    box-shadow: 0 0 10px rgba(0,0,0,.3);
}
.LeaderCommentFeed .comment-feed-container textarea {
    width: 100%;
}
@media only screen and (max-width: 696px) {
    .LeaderCommentFeed .comment-feed-container .new-comment-container {
        width: 100%;
        padding: 0;
        left: 0;
    }
}