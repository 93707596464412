@media only screen and (max-width: 1000px) {
    .EventCommentFeed .comment-feed-container .new-comment-container {
        width: 400px;
        position: fixed;
        bottom: 0;
        display: flex;
        flex-direction: row;
    }
}

@media only screen and (max-width: 696px) {
    .EventCommentFeed .comment-feed-container .new-comment-container {
        width: 100%;
        padding: 0;
        left: 0;
    }
}
