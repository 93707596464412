.WineComment .wine-comment-container {
    margin-top: 0px;
}
.WineComment .wine-comment-container i {
    font-size: .75rem;
}

.WineComment .wine-comment-container button {
    font-size: .75rem;
}

.WineComment .wine-comment-container .card {
    padding: 8px;
    margin-bottom: 16px;
}

.WineComment .wine-comment-wineName {
    font-size: .85rem;
    font-weight: bold;
}


.WineComment .wine-comment-text {
    padding-bottom: 8px;
    border-bottom: 1px solid var(--background-color);
}
.WineComment .wine-comment-footer {
    padding-top: 8px;
}
