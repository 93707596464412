.ExchangeList {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
}

.ExchangeList .primary-button {
    margin: 8px;
    width: 680px;
}

.ExchangeList .content-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.ExchangeList .content-container .list-container {
    max-width: 400px;
}

@media only screen and (max-width: 696px) {
    .ExchangeList .primary-button {
        margin: 8px;
        width: 98%;
    }
}

@media only screen and (max-width: 800px) {
    .ExchangeList .content-container .list-container {
        margin: 0;
        padding: 0;
        max-width: 98%;
    }
}

.SearchDialog {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #1b1e21;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
}

.SearchDialog .card {
    max-width: 480px;
}

.SearchDialog .card .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 98%;
    margin: 0 0 8px 0;
}

.SearchDialog .card .label {
    font-size: 1.25em;
}

.SearchDialog .card .primary-button {
    max-width: 100%;
    margin: 8px 0;
}

.SearchDialog .card .primary-button:last-child {
    margin: 0;
}
