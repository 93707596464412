.WineCommentFeed .comment-feed-container {
    padding-bottom: 192px;
}

.WineCommentFeed .comment-feed-container .new-comment-container {
    display: flex;
    flex-direction: row;
    width: 38%;
    position: fixed;
    bottom: 0;
    padding: 6px;
}
.WineCommentFeed .comment-feed-container .new-comment {
    background-color: white;
    padding: 8px;
    flex-basis: 100%;
    border: 1px solid rgba(0,0,0,.125);
    box-shadow: 0 0 10px rgba(0,0,0,.3);
}
.WineCommentFeed .comment-feed-container textarea {
    width: 100%;
}
.WineCommentFeed .comment-feed-container .btn-primary {
    width: 100%;
}
@media only screen and (max-width: 850px) {
    .WineCommentFeed .comment-feed-container .new-comment-container {
        width: 100%;
        right: 0;
        padding: 0;
    }
}