.ReferralBanner {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-evenly;
    background-color: #2f6f72;
    color: #ffffff;
    width: 80%;
    padding: 5px;
    margin-top: 7px;
    margin-bottom: 7px;
    margin-right: auto;
    margin-left: auto;
    horiz-align: center;
}
