.AddHolding .form .row .label {
    text-align: left;
}

.AddHolding .form .row input {
    flex-grow: 1;
    max-width: 420px;
    margin-left: 10px;
}

.AddHolding .content-container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 90%;
    padding: 0 8px;
}

.AddHolding .form .ErrorMessage {
    margin: 8px 0;
}


.AddHolding .form .row {
    margin-right: 15px;
    margin-left: 15px;
    margin-bottom: 10px;
}

@media only screen and (max-width: 696px) {
    .AddHolding .form {
        width: 100%;
    }

    .AddHolding .form .row {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 8px 0;
        margin: 0;
    }

    .AddHolding .form .primary-button {
        width: 100%;
    }
}
