.EventDetail {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    padding: 8px 16px 16px;
}
.EventDetail h1 {
    font-size: 2.25rem;
    text-align: center;
    margin: 16px;
}

.EventDetail a {
    color: rgba(17,172,192);
}

.EventDetail a:hover {
    color: white;
}

.EventDetail .content-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
}
.EventDetail .detail {
    width: 500px;
    flex-wrap: wrap;
    text-align: left;
    margin: 0 24px 0 0;
}

.EventDetail .comments {
    width: 500px;
    flex-wrap: wrap;
    justify-content: center;
}
.EventDetail .EventCommentFeed .new-comment-container {
    width: 500px;
}
.EventDetail .EventCommentFeed .new-comment-container .primary-button {
    width: 100%;
}

@media only screen and (max-width: 1000px) {
    .EventDetail .detail {
        width: 400px;
        flex-wrap: wrap;
        text-align: left;
        margin: 0;
    }
    .EventDetail .comments {
        width: 400px;
        flex-wrap: wrap;
        justify-content: center;
        margin: 16px 0 0 0 ;
    }
}

@media only screen and (max-width: 696px) {
    .EventDetail .detail {
        width: 100%;
        flex-wrap: wrap;
        text-align: left;
        margin: 0;
    }
    .EventDetail .comments {
        width: 100%;
        flex-wrap: wrap;
        justify-content: center;
        margin: 16px 0 0 0 ;
    }
}