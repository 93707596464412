.AddEvent {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    padding: 16px;
}

.AddEvent .form {
    width: 696px;
}

.AddEvent .form .row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 8px 0;
    margin: 0;
}

.AddEvent .form .row .label {
    text-align: left;
}

.AddEvent .form .row input {
    flex-grow: 1;
    max-width: 420px;
}

.AddEvent .form .ErrorMessage {
    margin: 8px 0;
}

.AddEvent .form .primary-button {
    width: 696px;
}

@media only screen and (max-width: 696px) {
    .AddEvent .form {
        width: 100%;
    }

    .AddEvent .form .row {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 8px 0;
        margin: 0;
    }

    .AddEvent .form .primary-button {
        width: 100%;
    }
}