.OgExchangeItem .link-button {
    color: #007bff;
    cursor: pointer;
}
.OgExchangeItem .link-button:hover {
    text-decoration: underline;
}

.EditExchangeItemModalDialog {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
}

.EditExchangeItemModalDialog .card {
    width: 480px;
    box-shadow: 0 0 5px #666666;
}

.EditExchangeItemModalDialog .card h5 {
    margin: 8px 0;
}

.EditExchangeItemModalDialog .card .button-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 8px 0 0 0;
}

.EditExchangeItemModalDialog .card .button-container button {
    width: 0;
    flex-grow: 1;
}

.EditExchangeItemModalDialog .card .button-container button:first-child {
    margin: 0 8px 0 0;
}

@media only screen and (max-width: 480px) {
    .EditExchangeItemModalDialog .card {
        width: 100%;
        box-shadow: 0 0 5px #666666;
    }
}
