

.SearchDialog .form {
    width: 100%
}

.HoldingList {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
}

.HoldingList h6 {
    margin-bottom: 0px;
}

.HoldingList .list-container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 90%;
    padding: 0 8px;
}

.HoldingList .primary-button {
    margin: 8px;
    width: 300px;
}

.HoldingList .list-container .primary-button {
    margin: 8px;
    width: 250px;
}

.HoldingList .card {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 70%;
    align-self: center;
    justify-content: space-evenly;
    border-radius: 6px;
    border: #0c5460;
    margin: auto;
    text-align: center;
    padding: 8px 8px 12px;
}

.HoldingList .cardcolumn {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 250px;
    align-self: center;
    justify-content: center;
    border-radius: 6px;
    border: #0c5460;
    margin: 8px;
    text-align: center;
    padding: 8px 8px 12px;
}

.HoldingList .cardcolumn .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 95%;
    align-self: center;
    justify-content: space-between;
    border-radius: 6px;
    border: #0c5460;
    margin: 4px;
    text-align: center;
    /*padding: 8px 8px 12px;*/
}

.HoldingList .HoldingItem {
    width: 100%;
    margin: 8px 0;
}

@media only screen and (max-width: 696px) {
    .HoldingList .primary-button {
        margin: 8px;
        width: 98%;
    }
}