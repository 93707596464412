.UpcomingWineItem.card .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 8px 0 0 0;
    width: 100%;
}

.UpcomingWineItem.card .row.text-center .col .changeable:hover {
    color: #0645AD;
    text-decoration: underline;
    cursor: pointer;
}

.UpcomingWineItem .secondary-button {
    width: 100px;
}

.UpcomingWineItem .description {
    font-size: 1.25rem;
}
.UpcomingWineItem img {
    width: 100%;
}
.UpcomingWineItem p {
    margin-top: 0;
}

.UpcomingWineItem #new-string {
    width: 100%;
}
