.Header {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    background-color: cadetblue;
    color: #ffffff;
}
.Header .brand a {
    font-size: 1.5rem;
    flex-grow: 1;
    margin: 8px;
    color: #ffffff;
}
.Header .navigation {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 8px;
}

.Header .navigation-item {
    padding: 8px 12px;
    color: #ffffff;
    cursor: pointer;
}
.Header .navigation-item:hover {
    padding: 8px 12px;
    color: #ffffff;
    background: #2f6f72;
}

.Header .navigation .navigation-item.selected {
    background: #8fcfd1;
    color: #000000;
}

.Header .navigation .navigation-item.selected:hover {
    background: #2f6f72;
    color: #ffffff;
}
