.LeaderItem {
}

.LeaderItem .link-button {
    color: #007bff;
    cursor: pointer;
}
.LeaderItem .link-button:hover {
    text-decoration: underline;
}
.LeaderItem .description {
    font-size: 1.25rem;
}
.LeaderItem .button {
    backgroundColor: 'rgba(52, 52, 52, 0.1)'
}
.LeaderItem img {
    width: 100%;
}
.LeaderItem p {
    margin-top: 0;
}
.LeaderItem a {
    display: block;
    margin-bottom: 8px;
}
.LeaderItem .vote-arrow {
    margin: 0 2px;
    cursor: pointer;
}

.EditLeaderItemModalDialog {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
}

.EditLeaderItemModalDialog .card .button-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 8px 0 0 0;
}

.EditLeaderItemModalDialog .card .button-container button {
    width: 0;
    flex-grow: 1;
}