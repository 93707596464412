.EmailContactModalDialog {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
}

.EmailContactModalDialog .card {
    width: 480px;
    box-shadow: 0 0 5px #666666;
}

.EmailContactModalDialog .card .title {
    color: #000000;
}

.EmailContactModalDialog .card input {
    margin: 8px 0 0 0;
}

.EmailContactModalDialog .card textarea {
    margin: 8px 0 0 0;
}

.EmailContactModalDialog .card .primary-button {
    margin: 8px 0 0 0;
}

.EmailContactModalDialog .card .ErrorMessage {
    margin: 8px 0 0 0;
}

@media only screen and (max-width: 480px) {
    .EmailContactModalDialog .card {
        width: 100%;
        box-shadow: 0 0 5px #666666;
    }
}
