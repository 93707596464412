.About {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 32px;
}
.About .text {
    /*max-width: 1000px;*/
    font-size: 1.1rem;
    justify-content: left;
    text-align: left;
    line-height: 1.4rem;
}

.About img {
    display: flex;
    flex-direction: column;
    height: 150px;
    width: 150px;
    margin: auto;
    display: block;
    justify-content: center;
}

.About h5 {
    font-weight: bold;
    margin-bottom: .1rem;
}
