.HoldingItem.card .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 8px 0 0 0;
    width: 100%;
}

.HoldingItem.card .row.text-center .col .changeable:hover {
    color: #0645AD;
    text-decoration: underline;
    cursor: pointer;
}

.HoldingItem.card .row.text-center .col .primary-button {
    border-radius: 15px;
    width: fit-content;
    margin: 0;
}

.HoldingItem .description {
    font-size: 1.25rem;
}
.HoldingItem img {
    width: 100%;
}
.HoldingItem p {
    margin-top: 0;
}

.HoldingItem #new-string {
    width: 100%;
}