.TopRated {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 8px 16px 0;
}
@media only screen and (max-width: 800px) {
    .TopRated {
        justify-content: center;
    }
}
